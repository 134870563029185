import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import development from './images/development.png'
import consultation from './images/consultation.png'
import administration from './images/administration.png'
import seo from './images/seo.png'
import { Done as Code } from '@material-ui/icons'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ServiceD from "./ServiceD";
import ServiceM from "./ServiceM";

const services = [
    {
        title: 'development',
        slug: 'development',
        image: development,
        content: [
            {
                icon: <Code/>,
                text: 'development.content.text1'
            },
            {
                icon: <Code/>,
                text: 'development.content.text2'
            },
            {
                icon: <Code/>,
                text: 'development.content.text3'
            },
            {
                icon: <Code/>,
                text: 'development.content.text4'
            }
        ]
    },
    {
        title: 'seo',
        slug: 'seo',
        image: seo,
        content: [
            {
                icon: <Code/>,
                text: 'seo.content.text1'
            },
            {
                icon: <Code/>,
                text: 'seo.content.text2'
            },
            {
                icon: <Code/>,
                text: 'seo.content.text3'
            },
            {
                icon: <Code/>,
                text: 'seo.content.text4'
            }
        ]
    },
    {
        title: 'administration',
        slug: 'administration',
        image: administration,
        content: [
            {
                icon: <Code/>,
                text: 'administration.content.text1'
            },
            {
                icon: <Code/>,
                text: 'administration.content.text2'
            },
            {
                icon: <Code/>,
                text: 'administration.content.text3'
            },
            {
                icon: <Code/>,
                text: 'administration.content.text4'
            }
        ]
    },
    {
        title: 'consultation_support',
        slug: 'consultation-support',
        image: consultation,
        content: [
            {
                icon: <Code/>,
                text: 'support.content.text1'
            },
            {
                icon: <Code/>,
                text: 'support.content.text2'
            },
            {
                icon: <Code/>,
                text: 'support.content.text3'
            },
            {
                icon: <Code/>,
                text: 'support.content.text4'
            }
        ]
    }
];

const styles = theme => ({
    root: {
        height: '100%',
        position: 'relative'
    },
});

const Service = ({classes, width}) => {
    return (
        <div className={classes.root}>
            {!isWidthDown('xs', width) ? <ServiceD items={services}/> : <ServiceM items={services}/>}
        </div>
    )
};

Service.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(styles)(Service))