import React from 'react'
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core'
import { Grid, LinearProgress } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import Container from "@material-ui/core/Container";

const styles = theme => createStyles({
    nav: {
        bottom: theme.spacing(2),
        width: '100%',
        position: 'absolute',
        background: 'rgba(0,0,0,0)',
        fontWeight: 'bold',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        color: theme.palette.common.white
    },
    item: {
        position: 'relative',
        cursor: 'pointer',
        zIndex: theme.zIndex.tooltip,
        fontSize: '1rem',
        opacity: .5,
        transition: 'opacity 0.3s linear',
        '&:hover': {
            opacity: 1
        }
    },
    progress: {
        position: 'absolute',
        width: '100%',
        marginTop: -theme.spacing(7)
    },
    position: {
        paddingBottom: theme.spacing(8),
        fontSize: '0.8125rem'
    },
    typography: {
        ...theme.typography.body1,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: '.9rem'
        }
    },
    linearColorPrimary: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderBottom: '0.3px solid rgba(255,255,255, .3)'

    },
    linearBarColorPrimary: {
        backgroundColor: '#EA2C2C',
    },
});

const SliderNav = ({ classes, slides, position, setPosition }) => {
    const k = 100 / slides.length;

    return (
        <div className={classes.nav}>
            <Container fixed={true}>
                <Grid container spacing={1}>
                    {slides.map((item, index) => {
                        return (
                            <Grid
                                xs
                                item
                                key={index}
                                onClick={() => setPosition(index)}
                                className={classes.item}
                                style={index === position ? { opacity: 1 } : {}}
                            >
                                <div
                                    variant={"inherit"}
                                    className={classes.position}
                                >
                                    0{index + 1}
                                </div>
                                <div variant={"inherit"} className={classes.typography}>
                                    <FormattedMessage id={item.title} />
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
            <LinearProgress
                variant={"determinate"}
                value={k * (position + 1)}
                className={classes.progress}
                classes={{
                    colorPrimary: classes.linearColorPrimary,
                    barColorPrimary: classes.linearBarColorPrimary,
                }}
            />
        </div>
    )
};

SliderNav.propTypes = {
    classes: PropTypes.object.isRequired,
    slides: PropTypes.array.isRequired,
    position: PropTypes.number.isRequired,
    setPosition: PropTypes.func.isRequired,
};

export default withStyles(styles)(SliderNav)