import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import 'typeface-montserrat';
import { Provider } from "react-redux"
import store from "./app/store";
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from "./app/theme"
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import { setLocale, detectLocale } from "./common/locale/localeOperation";
import { getSection, setSection } from "./pages/landing/landingOperation";
import {setScrollerAction} from "./pages/landing/landingAction";

addLocaleData([...en, ...ru]);

// get and set locale
const lang = detectLocale();
store.dispatch(setLocale(lang));

// get section
store.dispatch(setSection(getSection()));

//set scroller
store.dispatch(setScrollerAction(getSection()));

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
