import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Grid, Typography } from "@material-ui/core";
import { FormattedDate, FormattedMessage } from "react-intl";
import classnames from 'classnames';
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        height: '100%'
    },
    top: {
        height: '80%'
    },
    bottom: {
        height: '20%'
    },
    container: {
        padding: theme.spacing(2)
    },
    content: {
        overflow: 'auto',
        height: '100%'
    },
    miniAvatar: {
        width: '50%',
        height: '100%'
    },
    avatar: {},
    title: {
        display: 'flex',
        alignItems: 'flex-end',
        height: '50%'
    },
    cover: {
        height: '50%',
        color: '#fff',
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center'
    }
});

const ReviewMobile = ({ classes, reviews, handleSwipe }) => {
    const current = reviews[0];
    const next = reviews[1];
    const miniAvatarStyle = {
        background: `url(${next.user.profile.avatar}) center / cover no-repeat`
    };
    const avatarStyle = {
        background: `url(${current.user.profile.avatar}) center / cover no-repeat`
    };
    const coverStyle = {
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${current.article.image}) center / cover no-repeat`
    };


    return (
        <div className={classes.root}>
            <div className={classes.top}>
                    <Grid container className={classes.content}>
                        <Grid item xs={6} className={classnames(classes.container, classes.title)}>
                            <div>
                                <Typography variant={"h6"}>
                                    {current.user.profile.firstname + ' ' + current.user.profile.lastname}
                                </Typography>
                                <Typography variant={"caption"}>
                                    <FormattedDate
                                        value={current.createdAt}
                                        year='numeric'
                                        month='long'
                                        day='2-digit'
                                    />
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} className={classes.avatar} style={avatarStyle}></Grid>
                        <Grid item xs={12} className={classes.container} style={{height: '50%'}}>
                            <Typography>
                                {current.description}
                            </Typography>
                        </Grid>
                    </Grid>
            </div>
            <div className={classes.bottom}>
                <Grid container className={classes.container} style={{ height: '50%' }}>
                    <Grid item xs={6}>
                        <div className={classes.miniAvatar} style={miniAvatarStyle} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleSwipe('prev')}>
                            <FormattedMessage id={'prev'} />
                        </Button>
                        <Button onClick={() => handleSwipe('next')}>
                            <FormattedMessage id={'next'} />
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes.cover} style={coverStyle}>
                    <Button
                        variant={"outlined"}
                        component={Link}
                        to={'/articles/' + current.article.slug}
                        color={"inherit"}
                        fullWidth={true}
                    >
                        <FormattedMessage id={'show_project'} />
                    </Button>
                </div>
            </div>
        </div>
    )
};

ReviewMobile.propTypes = {
    classes: PropTypes.object.isRequired,
    reviews: PropTypes.array.isRequired,
    handleSwipe: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReviewMobile)
