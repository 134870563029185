import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {withStyles, createStyles, Slide} from '@material-ui/core'
import TopImage from './images/top.png'
import {Typography} from "@material-ui/core";
import {connect} from "react-redux";
import {getAbout} from "./aboutOperation";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {injectIntl} from "react-intl";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";

const styles = theme => createStyles({
    root: {
        height: '100%',
        background: theme.palette.common.black,
        position: 'relative',
        color: theme.palette.common.white,
        overflow: 'hidden',
    },
    topLayer: {
        background: `url(${TopImage}) center / cover no-repeat`,
        height: 144,
        width: '100%'
    },
    leftLayer: {
        position: 'absolute',
        width: 108,
        height: 360,
        bottom: theme.spacing(2),
        backgroundColor: '#1C2127'
    },
    container: {
        position: 'relative',
        zIndex: theme.zIndex.appBar
    },
    cover: {
        position: 'absolute',
        right: 108,
        bottom: 0,
        top: 72,
        width: 468,
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            width: 234,
            bottom: 72,
            top: 0,
            right: 0,
        }
    },
    subheader: {
        color: '#fff',
        align: 'center'
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(),
        fontFamily: "'Montserrat', 'Roboto'",
    },
    readMore: {
        marginTop: theme.spacing(10)
    }
});

const About = ({classes, about, lang, fetchAbout, intl, scroller, section}) => {
    const [show, setShow] = useState(false);
    const timeout = 800;

    useEffect(() => {
        fetchAbout()
    }, [fetchAbout, lang]);

    useEffect(() => {
        setShow(false);

        if (scroller === 'about') {
            setShow(true);
        }
    }, [scroller]);

    const style = {
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${about.image}) center / cover no-repeat`,
    };

    return (
        <div className={classes.root}>
            <div className={classes.topLayer}></div>
            <Slide direction={"down"} in={show} timeout={timeout}>
                <div className={classes.leftLayer}/>
            </Slide>
            <Slide direction={"up"} in={show && about.title !== undefined} timeout={timeout}>
                <div className={classes.cover} style={style}/>
            </Slide>
            <Container fixed={true} className={classes.container}>
                <Collapse in={show && about.title !== undefined} timeout={timeout}>
                    <Typography color={"inherit"} className={classes.title}
                                variant={'h3'}>{about.title}</Typography>
                </Collapse>
                <Collapse in={show && about.shortDescription !== undefined} timeout={timeout}>
                    <Typography
                        color={"inherit"}
                        dangerouslySetInnerHTML={{__html: about.shortDescription}}
                    />
                </Collapse>
                <Collapse in={show && about.shortDescription !== undefined} timeout={timeout}>
                    <Button component={Link} to={'/articles/' + about.slug} color={"inherit"}>
                        {intl.formatMessage({id: 'link.more'})}
                    </Button>
                </Collapse>
            </Container>
        </div>
    )
};

About.propTypes = {
    classes: PropTypes.object.isRequired,
    fetchAbout: PropTypes.func.isRequired,
    about: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    scroller: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    about: state.about.about,
    lang: state.locale.lang,
    scroller: state.landing.scroller,
    section: state.landing.section
});

const mapDispatchToProps = dispatch => ({
    fetchAbout: () => dispatch(getAbout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(About)))