import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import LinearProgress from "@material-ui/core/LinearProgress/index";
import { Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";

const styles = theme => ({
    container: {
        position: 'absolute',
        top: 'auto',
        bottom: theme.spacing(2),
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontFamily: "'Montserrat', 'Roboto'",
        color: theme.palette.common.white,
        zIndex: theme.zIndex.appBar + 2,
    },
    position: {
        fontFamily: "'Montserrat', 'Roboto'",
        fontWeight: 'bold'
    },
    progress: {
        flexGrow: 1,
    },
    linearColorPrimary: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderBottom: '0.3px solid rgba(255,255,255, .3)'
    },
    linearBarColorPrimary: {
        backgroundColor: '#EA2C2C',
    },
});

const SliderNavMobile = ({ classes, count, position, setPosition }) => {
    const k = 100 / count;

    const handlePosition = () => {
        if (position < count - 1) {
            setPosition(position + 1);
        } else {
            setPosition(0)
        }
    };

    return (
        <Container fixed={true} className={classes.container}>
            <Button
                variant={"text"}
                color={"inherit"}
                className={classes.position}
            >
                0{position + 1}
            </Button>
            <LinearProgress
                className={classes.progress}
                variant={"determinate"}
                value={k * (position + 1)}
                classes={{
                    colorPrimary: classes.linearColorPrimary,
                    barColorPrimary: classes.linearBarColorPrimary,
                }}
            />
            <Button
                variant={"text"}
                onClick={() => handlePosition()}
                color={"inherit"}
            >
                Next
            </Button>
        </Container>
    )
};

SliderNavMobile.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    setPosition: PropTypes.func.isRequired,
};

export default withStyles(styles)(SliderNavMobile)