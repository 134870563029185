import React from 'react';
import { createStyles, withStyles } from '@material-ui/core'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";

const activeColor = '#ea2c2c';

const styles = theme => createStyles({
    root: {
        color: '#fff',
        display: 'flex',
        transition: 'color .3s linear',
        alignItems: 'center',
        '&:hover': {
            color: activeColor
        },
    },
    label: {
        textTransform: 'uppercase',
        lineHeight: .8,
        fontWeight: 'bold',
        cursor: 'pointer',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
            lineHeight: 1.2,
        },
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    line: {
        width: 65,
        borderBottom: '4px solid ' + activeColor,
        position: 'absolute',
        left: - theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            width: 20,
            left: - theme.spacing(4),
            borderBottom: '2px solid ' + activeColor,
        }
    },
    circle: {
        borderRadius: '50%',
        width: 65,
        height: 65,
        border: '4px solid ' + activeColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: - theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            width: 25,
            height: 25,
            border: '2px solid ' + activeColor,
            right: - theme.spacing(4),
        }
    },
    number: {
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem'
        }
    }
});

const BurgerItem = ({classes, item, active, action, width, number}) => {
    const style = {
        marginLeft: item.offset
    };

    if (isWidthDown('xs', width) || isWidthDown('md', width)) {
        style.marginLeft = 0;
    }

    if (active) {
        style.color = activeColor
    }

    return (
        <div
            className={classes.root}
            style={style}
            onClick={() => action(item.id)}
        >

            <Typography
                variant={"h1"}
                component={'span'}
                className={classes.label}
            >
                {active && <div className={classes.line}/>}
                <FormattedMessage id={item.label} defaultMessage={item.label}/>
                {active &&
                <div className={classes.circle}>
                    <Typography variant={"h5"} className={classes.number}>{number}</Typography>
                </div>
                }
            </Typography>
        </div>
    )
};

BurgerItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
};

export default withWidth()(withStyles(styles)(BurgerItem));