import { CLOSE_FEEDBACK, OPEN_FEEDBACK } from "./feebackAction";

const initState = {
    open: false,
};

const feedbackReducer = (state = initState, action) => {
    switch (action.type) {
        case OPEN_FEEDBACK:
            return {
                open: true
            };
        case CLOSE_FEEDBACK:
            return {
                open: false
            };
        default: {
            return state
        }
    }
};

export default feedbackReducer;