import React from 'react';
import { Button, createStyles, withStyles } from '@material-ui/core';
import PropTypes from "prop-types";
import { openFeedback } from "../feedback/feebackAction";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import classnames from 'classnames'

const styles = theme => createStyles({
    button: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '0.75rem',
    }
});

const FeedbackPane = ({classes, color, openFeedbackPane, className}) => {
    return (
        <Button
            component={"span"}
            onClick={openFeedbackPane}
            className={classnames(classes.button, className)}
            style={{
                color: color
            }}
        >
            <FormattedMessage id={'request_call'}/>
        </Button>
    )
};

FeedbackPane.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
    openFeedbackPane: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    openFeedbackPane: () => dispatch(openFeedback()),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(FeedbackPane))