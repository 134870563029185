export default {
    en: {
        'intro.slider.title1': 'Encouragement',
        'intro.slider.description1': 'ADM specialists provide full range of services, starting with legal and IT-consulting, to developing and BPA decisions implementation.',

        'intro.slider.title2': 'Results-focused',
        'intro.slider.description2': 'We help companies to market the product and optimize it with minimal risk and established financial goals.',

        'intro.slider.title3': 'Time-bounded',
        'intro.slider.description3': 'We fix the commitment period for each stage of project. We respect deadlines.',

        'intro.slider.title4': 'Advanced',
        'intro.slider.description4': 'Our employees are experts in their field with more than 5 years’ experience and many successfully realized projects.',

        'intro.slider.title5': 'Expertise',
        'intro.slider.description5': 'We use only relevant developing and UX approach to make the most effective support solutions for a client. ',
    },
    ru: {
        'intro.slider.title1': 'Всесторонняя поддержка',
        'intro.slider.description1': 'Наши специалисты оказывают полный спектр услуг, начиная от юридического и IT-консалтинга, заканчивая разработкой и внедрением ПО для автоматизации бизнес-процессов.',

        'intro.slider.title2': 'Фокус на результат',
        'intro.slider.description2': 'Мы помогаем компаниям вывести или оптимизировать продукт, снизить риски и достигнуть заданных показателей.',

        'intro.slider.title3': 'Прозрачность сроков',
        'intro.slider.description3': 'Под каждый проект и этапы его реализации отводятся конкретные сроки. Мы – за соблюдение дедлайнов.',

        'intro.slider.title4': 'Экспертность',
        'intro.slider.description4': 'Наши сотрудники – профессионалы в своей области с опытом от 5 лет и множеством успешно реализованных проектов за плечами.',

        'intro.slider.title5': 'Технологичность',
        'intro.slider.description5': 'Мы используем только актуальные подходы к проектированию и UX, ведем разработку на современных фреймворках, чтобы клиент получил самое эргономичное и эффективное решение для своих задач.',
    }
}