import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        // useNextVariants: true,
        // fontSize: 14,
        // htmlFontSize: 16,
    },
});

export default theme;