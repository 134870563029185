import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core'
import Header from "../../header/Header";
import Intro from "./section/intro/Intro";
import About from "./section/about/About";
import Work from "./section/work/Work";
import Service from "./section/service/Service";
import Review from "./section/review/Review";
import Contact from "./section/contact/Contact";
import Partner from "./section/partner/Partner";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import withWidth from '@material-ui/core/withWidth'
import Section from "./section/Section";
import { scroller, Events } from 'react-scroll'
import useWheel from "../../utils/hooks/useWheel";
import { setSection } from "./landingOperation";
import { setScrollerAction } from "./landingAction";
import ToStart from "../../pane/ToStart";

const styles = theme => createStyles({
    root: {},
    toStart: {
        position: 'fixed',
        right: 0,
        bottom: theme.spacing(12),
        writingMode: 'vertical-lr',
    }
});

const offset = 145;
export const sections = [
    {id: 'intro', label: 'menu.label.home', offset: offset},
    {id: 'about', label: 'menu.label.about', offset: -offset},
    {id: 'service', label: 'menu.label.service', offset: 0},
    {id: 'work', label: 'menu.label.work', offset: 0},
    {id: 'partner', label: 'menu.label.partner', offset: offset},
    // {id: 'review', label: 'menu.label.review', offset: offset},
    {id: 'contact', label: 'menu.label.contact', offset: -offset},
];

const Landing = ({classes, section, setMonitor, setEndedScroll, width}) => {
    const wheel = useWheel();
    const getCurrentPosition = () => sections.indexOf(sections.filter(s => s.id === section)[0]);

    // for scrolling
    Events.scrollEvent.register('end', function (to) {
        if (to) {
            setEndedScroll(to)
        }
    });

    useEffect(() => {
        if (wheel !== 0) {
            let index = getCurrentPosition();

            if (wheel > 0) {
                index += 1;
            } else {
                if (wheel !== 0) {
                    index -= 1;
                }
            }

            if (sections[index] !== undefined) {
                const id = sections[index]['id'];
                setMonitor(id);
            }
        }

        // eslint-disable-next-line
    }, [wheel]);

    useEffect(() => {
        scroller.scrollTo(section, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            ignoreCancelEvents: true,
            isDynamic: true
        });
    }, [section]);

    return (
        <div className={classes.root}>
            <Header/>
            <Section id={'intro'}>
                <Intro/>
            </Section>
            <Section id={'about'}>
                <About/>
            </Section>
            <Section id={'service'}>
                <Service/>
            </Section>
            <Section id={'work'}>
                <Work/>
            </Section>
            <Section id={'partner'}>
                <Partner/>
            </Section>
            {/*<Section id={'review'}>*/}
            {/*    <Review/>*/}
            {/*</Section>*/}
            <Section id={'contact'}>
                <Contact/>
            </Section>
            <ToStart className={classes.toStart} color={section === 'contact' ? '#000' : '#fff'}/>
        </div>
    )
};

Landing.propTypes = {
    classes: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    setMonitor: PropTypes.func.isRequired,
    setEndedScroll: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    section: state.landing.section,
});
const mapDispatchToProps = dispatch => ({
    setMonitor: section => dispatch(setSection(section)),
    setEndedScroll: scroller => dispatch(setScrollerAction(scroller))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(withWidth()(Landing))))