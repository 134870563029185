import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import WorkNav from "./WorkNav";
import sleep from '../../../../utils/sleep'
import Slide from "@material-ui/core/Slide";
import { CoverLink } from "./Work";
import { injectIntl } from "react-intl";

const styles = theme => createStyles({
    root: {
        height: '100%',
        overflow: 'hidden'
    },
    gridTop: {
        height: '40%',
        overflow: 'hidden',
        overflowY: 'scroll'
    },
    gridBottom: {
        position: 'relative',
        height: '60%'
    },
    title: {
        textTransform: 'uppercase',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        fontWeight: 'bold',
    },
    coverLeft: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    coverRight: {
        position: 'absolute',
        right: 0,
        width: '50%',
        height: '70%',
    },
    signLeft: {
        position: 'absolute',
        color: '#fff',
        textDecoration: 'none',
        right: -theme.spacing(1),
        padding: theme.spacing(1),
        background: 'rgba(234, 44, 44, .9)',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        textTransform: 'uppercase',
        fontWeight: 'bold',
        zIndex: 100,
        top: '20%',
    },
    signRight: {
        position: 'absolute',
        color: '#fff',
        textDecoration: 'none',
        left: -theme.spacing(2),
        padding: theme.spacing(1),
        background: 'rgba(234, 44, 44, .9)',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        textTransform: 'uppercase',
        fontWeight: 'bold',
        zIndex: 100,
        top: '50%',
    },
    nav: {
        position: 'absolute',
        bottom: 0
    },
    more: {
        color: '#fff',
        textDecoration: 'none',
        padding: theme.spacing(1),
        background: 'rgba(234, 44, 44, .9)',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
});

const WorkMobile = ({ classes, works, intl, scroller }) => {
    const [current, setCurrent] = useState(works[0]);
    const [right, setRight] = useState(works[1]);
    const [open, setOpen] = useState(false);
    const timeout = 800;

    useEffect(() => {
        setOpen(false);

        if (scroller === 'work') {
            setOpen(true);
        }
    }, [scroller]);

    const next = async () => {
        setOpen(false);
        await sleep(800);
        const index = works.indexOf(right);

        if (works[index + 1]) {
            setRight(works[index + 1]);
            setCurrent(right);
        }

        setOpen(true)
    };

    const prev = async () => {
        setOpen(false);
        await sleep(timeout);
        const index = works.indexOf(current);

        if (works[index - 1]) {
            setCurrent(works[index - 1]);
            setRight(current);
        }

        setOpen(true)
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.gridTop}>
                <Container fixed>
                    <Slide direction={"left"} in={open} timeout={timeout}>
                        <div>
                            <Typography
                                color={"inherit"}
                                className={classes.title}
                                gutterBottom
                                variant={"h6"}
                            >
                                {current.title}
                            </Typography>
                            <Typography color={"inherit"} variant={"body1"}
                                        dangerouslySetInnerHTML={{ __html: current.shortDescription }} />
                            <Typography
                                component={CoverLink}
                                variant={'body2'}
                                className={classes.more}
                                to={'/articles/' + current.slug}
                            >
                                {intl.formatMessage({ id: 'link.more' })}
                            </Typography>
                        </div>
                    </Slide>
                </Container>
            </Grid>
            <Grid item xs={6} className={classes.gridBottom}>
                <Slide in={open} direction={"down"} timeout={timeout}>
                    <div
                        className={classes.coverLeft}
                        style={{
                            background: `url(${current.image}) center / cover no-repeat`
                        }}
                    >
                        <Typography
                            component={CoverLink}
                            to={'/articles/' + current.slug}
                            className={classes.signLeft}
                            variant={"h4"}
                        >
                            {current.title[0]}
                        </Typography>
                    </div>
                </Slide>
            </Grid>
            <Grid item xs={6} className={classes.gridBottom}>
                <Slide in={open} direction={"up"} timeout={timeout}>
                    <div
                        className={classes.coverRight}
                        style={{
                            background: `url(${right.image}) center / cover no-repeat`
                        }}
                    >
                        <Typography
                            component={CoverLink}
                            to={'/articles/' + right.slug}
                            className={classes.signRight}
                            variant={"h4"}
                        >
                            {right.title[0]}
                        </Typography>
                    </div>
                </Slide>
                <WorkNav
                    works={works}
                    right={right}
                    left={current}
                    next={next}
                    prev={prev}
                    className={classes.nav}
                />
            </Grid>
        </Grid>
    )
};

WorkMobile.propTypes = {
    classes: PropTypes.object.isRequired,
    works: PropTypes.array,
    intl: PropTypes.object.isRequired,
    scroller: PropTypes.string.isRequired,
};

export default injectIntl(withStyles(styles)(WorkMobile));