import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from "react-redux";
import classnames from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl } from "react-intl";
import { sections } from '../pages/landing/Landing'
import { setSection } from "../pages/landing/landingOperation";

const style = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    dot: {
        background: 'rgba(255, 255, 255, .3)',
        padding: theme.spacing() / 2,
        marginLeft: theme.spacing() / 2,
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer'
    },
    dotBlack: {
        background: 'rgba(0, 0, 0, .3)',
    },
    activeBlack: {
        background: 'rgba(0, 0, 0, 1)',
        // background: '#EA2C2C',
    },
    active: {
        background: 'rgba(255, 255, 255, 1)'
        // background: '#EA2C2C',
    }
});

const DotPane = ({ classes, section, setMonitor, className, intl }) => {
    return (
        <nav className={classnames(classes.root, className)}>
            {sections.map((item, index) => {
                let className = classes.dot;

                if (section === 'contact') {
                    className = classnames(className, classes.dotBlack)
                }


                if (section === item.id) {
                    className = classnames(className, section !== 'contact' ? classes.active : classes.activeBlack)
                }

                return (
                    <Tooltip title={intl.formatMessage({ id: item.label })} placement={"bottom"} key={index}>
                        <div
                            className={className}
                            onClick={() => setMonitor(item.id)}
                        />
                    </Tooltip>
                )
            })}
        </nav>
    )
};

DotPane.propTypes = {
    classes: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    setMonitor: PropTypes.func.isRequired,
    className: PropTypes.string,
    intl: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    section: state.landing.section,
    lang: state.locale.lang
});

const mapDispatchToProps = dispatch => ({
    setMonitor: section => dispatch(setSection(section))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(style)(DotPane)))