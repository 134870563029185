import axios from "../utils/axios";

export const getArticleBySlug = async slug => {
    return await axios({
        method: 'GET',
        url: '/articles/' + slug
    });
};

export const getArticles = async type => {
    let queryStr;

    if (type) {
        queryStr = '?type=' + type;
    }

    return await axios({
        method: 'GET',
        url: '/articles' + queryStr
    });
}