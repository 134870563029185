import React from 'react'
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles'
import logo from '../pages/landing/section/intro/images/logo.svg'
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { setSectionAction } from "../pages/landing/landingAction";
import { Helmet } from "react-helmet";
import DotPane from "../pane/DotPane";
import Burger from "../burger/Burger";

const styles = theme => ({
    root: {
        background: 'rgba(0,0,0,0)',
    },
    container: {
        display: 'flex',
        padding: theme.spacing(2)
    },
    subheader: {
        color: '#fff',
        textTransform: 'uppercase',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        marginLeft: 4,
        fontSize: '0.59rem'
    },
});

const Header = ({classes, section, setSection, intl}) => {
    const goToStart = () => {
        setSection('intro')
    };

    return (
        <AppBar position="fixed" elevation={0} className={classes.root}>
            <Helmet
                titleTemplate="ADM - %s"
            >
                <title>{intl.formatMessage({id: 'app.title'})}</title>
                <meta name="description" content={intl.formatMessage({id: 'app.description'})}/>
                <meta name="keywords" content={intl.formatMessage({id: 'app.title'})}/>
                <meta property="og:title" content={intl.formatMessage({id: 'app.title'})}/>
                <meta property="og:description" content={intl.formatMessage({id: 'app.description'})}/>
            </Helmet>
            <Toolbar className={classes.toolbar}>
                <Burger/>
                <img
                    src={logo}
                    alt="logo"
                    onClick={goToStart}
                    style={{cursor: 'pointer'}}
                />
                <div>
                    <Typography
                        className={classes.subheader}
                        variant={"caption"}
                        style={(section === 'contact' || section === 'review') ? {color: '#000'} : {}}
                        display={"block"}
                        align={"left"}
                    >
                        <FormattedMessage id={'menu.label.' + section}/>
                    </Typography>
                    <DotPane/>
                </div>
            </Toolbar>
        </AppBar>
    )
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    setSection: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    section: state.landing.section,
    lang: state.locale.lang
});
const mapDispatchToProps = dispatch => ({
    setSection: id => dispatch(setSectionAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(Header)));