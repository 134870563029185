import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Grid, Typography } from "@material-ui/core";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = theme => ({
    leftSide: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    rightSide: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
    },
    rightSideTitle: {
        fontWeight: 'bold',
        fontSize: 100,
        fontFamily: "'Montserrat', 'Roboto'"
    },
    showBtnWrapper: {
        position: 'absolute',
        bottom: '20%'
    },
    header: {
        width: '100%',
        margin: '0 auto',
        maxWidth: 1100,
        padding: theme.spacing(2)
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    container: {
        margin: '0 auto',
        width: '100%',
    },
    title: {
        display: 'inline-block',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        height: 400,
        width: '100%',
        background: '#bbb'
    },
    avatarMini: {
        width: 144,
        height: 134,
        background: '#975',
        marginRight: '10%'
    },
});

const ReviewDesktop = ({ classes, reviews, handleSwipe, width }) => {
    const current = reviews[0];
    const next = reviews[1];
    const currentStyle = {
        background: `url(${current.user.profile.avatar}) center / cover no-repeat`
    };
    const nextStyle = {
        background: `url(${current.user.profile.avatar}) center / cover no-repeat`
    };
    const projectStyle = {
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${next.article.image}) center / cover no-repeat`
    }

    return (
        <div style={{ height: '100%' }}>
            <Grid container style={{ height: '100%' }}>
                <Grid item sm={8} xs={12} className={classes.leftSide}>
                    <div className={classes.header}>
                        <div className={classes.titleWrapper}>
                            <div className={classes.title}>
                                <Typography
                                    variant={"h4"}>{current.user.profile.firstname + ' ' + current.user.profile.lastname}</Typography>
                                <Typography variant={"caption"} gutterBottom>
                                    <FormattedDate
                                        value={current.createdAt}
                                        year='numeric'
                                        month='long'
                                        day='2-digit'
                                    />
                                </Typography>
                            </div>
                        </div>
                        <Grid container className={classes.container} spacing={2}>
                            <Grid item sm={4} xs={12}>
                                <div className={classes.avatar} style={currentStyle} />
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                <Typography>{current.description}</Typography>
                            </Grid>
                        </Grid>
                        <div align={'center'}>
                            <Button onClick={() => handleSwipe('prev')}>
                                <FormattedMessage id={'prev'} />
                            </Button>
                            <Button onClick={() => handleSwipe('next')}>
                                <FormattedMessage id={'next'} />
                            </Button>
                        </div>
                    </div>
                    {!isWidthDown('xs', width) &&
                    <div className={classes.footer} align={'right'}>
                        <div className={classes.avatarMini} style={nextStyle} />
                    </div>
                    }
                </Grid>
                <Grid item sm={4} xs={12} className={classes.rightSide} style={projectStyle}>
                    {!isWidthDown('xs', width) &&
                    <Typography className={classes.rightSideTitle}
                                color={"inherit"}>{current.article.title[0]}</Typography>
                    }
                    <div className={classes.showBtnWrapper}>
                        <Button
                            variant={"outlined"}
                            component={Link}
                            to={'/articles/' + current.article.slug}
                            color={"inherit"}
                        >
                            <FormattedMessage id={'show_project'} />
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
};

ReviewDesktop.propTypes = {
    classes: PropTypes.object.isRequired,
    reviews: PropTypes.array.isRequired,
    handleSwipe: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired
};

export default withWidth()(withStyles(styles)(ReviewDesktop))