import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core'
import { getWorks } from "./workOperation";
import { connect } from "react-redux";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { injectIntl } from "react-intl";
import WorkDesktop from './WorkDesktop'
import WorkMobile from './WorkMobile'
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        height: '100%',
        background: '#000',
        color: '#fff',
        paddingTop: 100,
    },
    rootInner: {
        position: 'relative',
        height: '100%',
    }
});

export const CoverLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const Work = ({ classes, works, fetchWorks, lang, width, scroller }) => {
    useEffect(() => {
        fetchWorks();
    }, [fetchWorks, lang]);

    return (
        <div className={classes.root}>
            {works.length > 0 &&
            <div className={classes.rootInner}>
                {!isWidthDown('xs', width) ? <WorkDesktop works={works} scroller={scroller} /> : <WorkMobile works={works} scroller={scroller} />}
            </div>
            }
        </div>
    )
};

Work.propTypes = {
    classes: PropTypes.object.isRequired,
    works: PropTypes.array,
    lang: PropTypes.string.isRequired,
    fetchWorks: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
    scroller: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    works: state.work.data,
    lang: state.locale.lang,
    scroller: state.landing.scroller,
});
const mapDispatchToProps = dispatch => ({
    fetchWorks: () => dispatch(getWorks())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl((withWidth()(withStyles(styles)(Work)))))