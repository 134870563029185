import React, { useState } from 'react'
import { withStyles, createStyles, Container } from '@material-ui/core'
import PropTypes from "prop-types";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import SliderNavMobile from "./SliderNavMobile";
import SliderNav from "./SliderNav";
import { Typography } from "@material-ui/core";
import Lines from "./Lines";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import slides from './slides'
import Collapse from "@material-ui/core/Collapse";
import RightPane from "../../../../pane/RightPane";
import LeftPane from "../../../../pane/LeftPane";

const styles = theme => createStyles({
    slider: {
        position: 'relative',
        zIndex: 100,
        height: '100%',
        overflow: 'hidden'
    },
    slide: {
        transition: 'opacity 1s',
        opacity: 0,
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        minWidth: '100%',
        minHeight: '100%'
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        color: '#fff'
    },
    title: {
        textTransform: 'uppercase',
        fontFamily: "Montserrat",
        fontWeight: "bold",
        margin: `${theme.spacing(4)}px auto`,
        [theme.breakpoints.down('md')]: {
            fontSize: '4rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem'
        },
    },
});

const Intro = ({classes, width, section}) => {
    const [position, setPosition] = useState(0);

    let startX = 0;
    let startY = 0;

    const touchStart = event => {
        const touches = event.changedTouches[0];
        startX = touches.pageX;
        startY = touches.pageY;
    };


    const touchEnd = event => {
        const touches = event.changedTouches[0];
        const diffX = startX - touches.pageX;
        const diffY = startY - touches.pageY;

        if (Math.abs(diffX) > Math.abs(diffY)) {
            if (diffX > 0) {
                swipe('left')
            } else {
                swipe('right')
            }
        }
    };

    const swipe = action => {
        let pos = position;

        if (action === 'left') {
            pos += 1;
        } else {
            pos -= 1;
        }

        if (!slides[pos]) {
            pos = 0;
        }

        setPosition(pos)
    };

    return (
        <div className={classes.slider} onTouchStart={touchStart} onTouchEnd={touchEnd}>
            {!isWidthDown('xs', width) && <Lines/>}
            {slides.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={classes.slide}
                        style={position === index ? {opacity: 1} : {opacity: 0}}
                    >
                        {item.type === 'video' &&
                        <video
                            playsInline={true}
                            autoPlay={true}
                            muted={true}
                            loop
                            className={classes.video}
                        >
                            <source src={item.path} type="video/mp4"/>
                        </video>
                        }
                        {item.type === 'image' &&
                        <div
                            className={classes.image}
                            style={{
                                background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${item.path}) center / cover no-repeat`
                            }}
                        />
                        }
                        <LeftPane/>
                        <RightPane/>
                        <Container fixed={true} className={classes.container}>
                            <div style={{width: '100%'}}>
                                <Collapse in={position === index && section === 'intro'} timeout={1000}>
                                    <Typography variant={"h1"} color={"inherit"} className={classes.title}>
                                        <FormattedMessage id={item.title}/>
                                    </Typography>
                                </Collapse>
                                <Collapse in={position === index && section === 'intro'} timeout={3000}>
                                    <Typography variant={"body2"} color={"inherit"}>
                                        <FormattedMessage id={item.description}/>
                                    </Typography>
                                </Collapse>
                            </div>
                        </Container>
                    </div>
                )
            })}
            {!isWidthDown('xs', width) ?
                <SliderNav slides={slides} position={position} setPosition={setPosition}/>
                :
                <SliderNavMobile count={slides.length} position={position}
                                 setPosition={setPosition}/>
            }
        </div>)
}

Intro.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    lang: state.locale.lang,
    section: state.landing.section
});

export default connect(mapStateToProps)(withWidth()(withStyles(styles)(Intro)));