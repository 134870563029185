import React from 'react';
import {withStyles, createStyles} from '@material-ui/core'
import PropTypes from "prop-types";
import EmptyImage from './images/empty.jpg'
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {FormattedMessage} from "react-intl";

const styles = createStyles({
    root: {
        height: '100%',
        background: `url(${EmptyImage}) center / cover no-repeat`,
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        textTransform: 'uppercase',
        fontWeight: 'bold'

    }
});

const Empty = ({classes}) => {
    return (
        <div className={classes.root}>
            <Container fixed>
                <Typography
                    align={"center"}
                    variant={"h2"}
                    component={"div"}
                    className={classes.text}
                >
                    <FormattedMessage id={'page.empty'}/>
                </Typography>
            </Container>
        </div>
    )
};

Empty.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Empty);