import { setSectionAction } from "./landingAction";

const LANDING_SECTION = 'landingSection';

export const setSection = section => {
    return dispatch => {
        localStorage.setItem(LANDING_SECTION, section);
        dispatch(setSectionAction(section));
    }
};

export const getSection = () => {
    return localStorage.getItem(LANDING_SECTION) || 'intro'
};