import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";

const styles = theme => ({
    lines: {
        position: 'absolute',
        width: '100%',
        zIndex: theme.zIndex.tooltip
    },
    lineLeft: {
        position: 'relative',
        '&:before': {
            position: 'absolute',
            content: '""',
            paddingLeft: 1,
            height: '60vh',
            borderLeft: '0.3px solid rgba(255,255,255, .3)',
        }
    },
    lineRight: {
        position: 'relative',
        '&:after': {
            position: 'absolute',
            content: '""',
            right: 0,
            paddingRight: 1,
            height: '60vh',
            borderRight: '0.3px solid rgba(255,255,255, .3)',
        }
    },
    lineCenter: {
        position: 'relative',
        '&:before': {
            position: 'absolute',
            content: '""',
            paddingLeft: 1,
            height: '35vh',
            borderLeft: '0.3px solid rgba(255,255,255, .3)',
        },
        '&:after': {
            position: 'absolute',
            content: '""',
            right: 0,
            paddingRight: 1,
            height: '20vh',
            borderRight: '0.3px solid rgba(255,255,255, .3)',
        }
    },
    lineMiddle: {
        position: 'relative',
        '&:before': {
            position: 'absolute',
            content: '""',
            right: 0,
            paddingRight: 1,
            height: '35vh',
            borderRight: '0.3px solid rgba(255,255,255, .3)',
        }
    },
});

const Lines = ({classes}) => {
    return (
        <div className={classes.lines}>
            <Container fixed={true}>
                <Grid container>
                    <Grid item xs className={classes.lineLeft}></Grid>
                    <Grid item xs className={classes.lineCenter}></Grid>
                    <Grid item xs className={classes.lineMiddle}></Grid>
                    <Grid item xs className={classes.lineRight}></Grid>
                </Grid>
            </Container>
        </div>
    )
};

Lines.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Lines);