import React, { useState } from 'react'
import PropTypes from "prop-types";
import { createStyles, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, injectIntl } from "react-intl";
import sleep from "../../../../utils/sleep";
import { connect } from 'react-redux'
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Container from "@material-ui/core/Container";
import ArrayBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => createStyles({
    root: {
        height: '100%'
    },
    header: {
        height: '40%',
        display: 'flex'
    },
    footer: {
        height: '60%',
        background: '#000',
        color: '#fff'
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(8),
        height: '100%',
        overflow: 'scroll'
    },
    item: {
        position: 'relative',
        height: '100%',
        width: 0,
        transition: 'all .5s',
        overflow: 'hidden',
    },
    itemInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'relative',
    },
    link: {
        textDecoration: 'none',
        position: 'absolute',
        color: '#fff',
        bottom: 0,
        width: '100%'
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: ["Montserrat", "Roboto"].join(","),
        padding: theme.spacing(2)
    },
    sign: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    arrow: {
        fontSize: '4rem',
        color: 'rgba(255, 255, 255, .2)',
    }
});

const ServiceM = ({classes, items, intl, scroller}) => {
    const [list] = useState(items);
    const [active, setActive] = useState(list[0]);
    const [next, setNext] = useState(list[1]);
    const [slided, setSlided] = useState(true);
    const timeout = 500;

    let startX = 0;
    let startY = 0;

    const touchStart = event => {
        const touches = event.changedTouches[0];
        startX = touches.pageX;
        startY = touches.pageY;
    };


    const touchEnd = (event) => {
        const touches = event.changedTouches[0];
        const diffX = startX - touches.pageX;
        const diffY = startY - touches.pageY;

        if (Math.abs(diffX) > Math.abs(diffY)) {
            if (diffX > 0) {
                swipeLeft()
            } else {
                swipeRight()
            }
        }
    };

    const swipeLeft = async () => {
        const item = list[list.indexOf(next) + 1];

        if (item) {
            setSlided(false);
            setActive(next);
            setNext(item);
            await sleep(timeout);
            setSlided(true);

        } else {
            if (active !== next) {
                setSlided(false);
                setActive(next);
                await sleep(timeout);
                setSlided(true);
            }
        }
    };

    const swipeRight = async () => {
        const item = list[list.indexOf(active) - 1];

        if (item) {
            setSlided(false);
            setNext(active);
            setActive(item);
            await sleep(timeout);
            setSlided(true);
        }
    };

    return (
        <div className={classes.root}>
            <div
                className={classes.header}
                onTouchStart={touchStart}
                onTouchEnd={touchEnd}
            >
                {list.map((item, index) => {
                    const style = {
                        background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${item.image}) center / cover no-repeat`,
                        width: '0%',
                    };

                    if (active === item) {
                        style.width = '70%';
                    }

                    if (next === item) {
                        style.width = '30%';
                        style.background = `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${item.image}) center / cover no-repeat`
                    }

                    if (item === active && item === next) {
                        style.width = '100%';
                        style.background = `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${item.image}) center / cover no-repeat`;
                    }

                    const title = intl.formatMessage({id: item.title});

                    return (
                        <div
                            key={index}
                            className={classes.item}
                            style={style}
                        >
                            {active === item ?
                                <Link to={'/articles/' + item.slug} className={classes.link}>
                                    <Fade in={slided && scroller === 'service'} timeout={timeout}>
                                        <Typography
                                            variant={"h6"}
                                            className={classes.title}
                                            display={"block"}
                                        >
                                            {title}
                                        </Typography>
                                    </Fade>
                                </Link> :
                                <div className={classes.itemInner}>
                                    <Fade in={slided && scroller === 'service'} timeout={timeout}>
                                        <ArrayBackIcon className={classes.arrow}/>
                                    </Fade>
                                    <div className={classes.link}>
                                        <Fade in={slided && scroller === 'service'} timeout={timeout}>
                                            <Typography
                                                variant={"h6"}
                                                className={classes.title}
                                                align={"right"}
                                            >
                                                {title[0]}
                                            </Typography>
                                        </Fade>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
            <div className={classes.footer}>
                <Container className={classes.container}>
                    <Grid container style={{height: '100%'}}>
                        {active.content.map((val, key) => {
                            return (
                                <Grid
                                    key={key}
                                    item
                                    xs={12}
                                >
                                    <Fade in={slided && scroller === 'service'} timeout={timeout}>
                                        <div className={classes.sign}>
                                            <div className={classes.icon}>{val.icon}</div>
                                            <Typography color={"inherit"}>
                                                <FormattedMessage id={val.text} defaultMessage={val.text}/>
                                            </Typography>
                                        </div>
                                    </Fade>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </div>
        </div>
    )
};

ServiceM.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    scroller: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    scroller: state.landing.scroller,
});

export default connect(mapStateToProps)(injectIntl(withStyles(styles)(ServiceM)))