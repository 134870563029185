import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import { IconButton, Modal } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import { connect } from "react-redux";
import SvgIcon from '@material-ui/core/SvgIcon';
import { withRouter } from 'react-router-dom';
import { sections as menuItems } from '../pages/landing/Landing';
import { setSection } from "../pages/landing/landingOperation";
import BurgerItem from "./BurgerItem";
import Slide from "@material-ui/core/Slide";

const styles = theme => ({
    menuWrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(0,0,0,.8)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
    },
    paper: {
        outline: 'none',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
        background: 'rgba(0,0,0,0.5)'
    },
    close: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    nav: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
        }
    },
});

const timeout = 800;

const Burger = ({classes, className, setMonitor, section}) => {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);

    const handleOpen = () => {
        setOpen(!open);

        if (show) {
            setShow(false);
        }

    };

    const handleSection = id => {
        handleOpen();
        setMonitor(id)
    };

    return (
        <div className={className}>
            <IconButton
                edge="start"
                aria-label="open menu"
                onClick={handleOpen}
                style={section === 'contact' || section === 'review' ? {color: '#000'} : {color: '#fff'}}
            >
                <SvgIcon viewBox={'0 0 10 20'}>
                    <path d="M0 0L0 13.2H3L3 0L0 0Z"/>
                    <path d="M7 0L7 22L10 22L10 0L7 0Z"/>
                </SvgIcon>
            </IconButton>
            <Modal
                open={open}
                onClose={handleOpen}
                onRendered={() => setShow(true)}
            >
                <div className={classes.paper}>
                    <IconButton
                        color="inherit"
                        aria-label="close menu"
                        onClick={handleOpen}
                        className={classes.close}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <div className={classes.nav}>
                        {menuItems.map((item, index) => {
                            return (
                                <Slide
                                    key={index}
                                    in={open}
                                    timeout={timeout}
                                    direction={item.offset < 0 ? "left" : "right"}
                                >
                                    <div>
                                        <BurgerItem
                                            item={item}
                                            number={index + 1}
                                            active={section === item.id}
                                            action={handleSection}
                                        />
                                    </div>
                                </Slide>
                            )
                        })}
                    </div>
                </div>
            </Modal>
        </div>
    )
};

Burger.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    setMonitor: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    section: state.landing.section
});

const mapDispatchToProps = dispatch => ({
    setMonitor: section => dispatch(setSection(section))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Burger)))
