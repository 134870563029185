import serviceMessages from './serviceMessages';
import introMessages from './introMessages'

const messages = {
    en: {
        'contacts': 'contacts',
        'call_request': 'call request',
        'menu.label.home': 'home',
        'menu.label.intro': 'intro',
        'menu.label.about': 'about us',
        'menu.label.work': 'works',
        'menu.label.service': 'services',
        'menu.label.review': 'reviews',
        'menu.label.contact': 'contacts',
        'menu.label.partner': 'partners',
        'label.phone': 'phone',
        'label.name': 'name',
        'label.description': 'description',
        'form.send_form': 'send form',
        'have_questions': 'Have questions',
        'request_call': 'Request a call',
        'link.more': 'more',
        'bank_requisites': 'bank requisites',
        'all_rights_reserved': 'All rights reserved',
        'development': 'development',
        'administration': 'Administration',
        'seo': 'Internet advertisement',
        'consultation_support': 'Consultation and support',
        'to_start': 'to start',
        'prev': 'prev',
        'next': 'next',
        'show_project': 'show project',
        'adm_group_llc':  'ADM Group LLC',
        'page.empty': 'The page is empty',
        'app.title': 'Development, administration, consultation, consulting, guarantee, turnkey sites, landings, etc',
        'app.description': 'We are provide good skills with development, administration, consultation and more thinks such as blockchain, etc. Working with physical and legal persons',
    },
    ru: {
        'contacts': 'контакты',
        'call_request': 'заказать звонок',
        'menu.label.home': 'главная',
        'menu.label.intro': 'главная',
        'menu.label.about': 'о нас',
        'menu.label.work': 'работы',
        'menu.label.service': 'услуги',
        'menu.label.review': 'отзывы',
        'menu.label.contact': 'контакты',
        'menu.label.partner': 'партнеры',
        'label.phone': 'телефон',
        'label.name': 'имя',
        'label.description': 'описание',
        'form.send_form': 'отправить форму',
        'have_questions': 'Есть вопросы',
        'request_call': 'Заказать звонок',
        'link.more': 'подробнее',
        'bank_requisites': 'Банковские реквизиты',
        'all_rights_reserved': 'Все права защищены',
        'development': 'Разработка',
        'administration': 'Администрирование',
        'seo': 'Интернет-реклама',
        'consultation_support': 'Консультирование и поддержка',
        'to_start': 'в начало',
        'prev': 'назад',
        'next': 'вперед',
        'show_project': 'Смотреть проект',
        'adm_group_llc': 'ООО "ЭйДиЭм Групп"',
        'page.empty': 'Страница пуста',
        'app.title': 'Разработка, администрирование, консультирование, консалтинг, гарантии, сайты под ключ, лендинги и другое',
        'app.description': 'Качественно програмируем, админитстируем, консультируем. Так же предоставляем ряд услуг, таких как разработка с использованием blockchain и многое другое. Работаем с физичкими и уридическими лицами',
    }
};

export default {
    ru: {
        ...messages.ru,
        ...serviceMessages.ru,
        ...introMessages.ru
    },
    en: {
        ...messages.en,
        ...serviceMessages.en,
        ...introMessages.en
    }
}