import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import { Grid, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import RequestCallForm from "./FeedbackForm";
import { FormattedMessage } from "react-intl";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { closeFeedback } from "./feebackAction";
import { connect } from "react-redux";

const styles = theme => ({
    root: {
        color: theme.palette.common.white,
    },
    paper: {
        outline: 'none',
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '60%',
        background: theme.palette.common.white,
        zIndex: 99999,
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100vh'
        }
    },
    header: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    formTitle: {
        fontWeight: 'bold',
        fontFamily: "'Montserrat', 'Roboto'",
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem'
        }
    }
});

const Feedback = ({classes, width, open, closeFeedbackPane}) => {
    return (
        <div className={classes.root}>
            <Modal
                ria-labelledby="feedback-modal-title"
                aria-describedby="feedback-modal-description"
                open={open}
                onClose={closeFeedbackPane}
            >
                <div className={classes.paper}>
                    <div className={classes.header}>
                        <Typography variant={'h3'} className={classes.formTitle}>
                            <FormattedMessage id={'request_call'}/>
                        </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="close menu"
                            onClick={closeFeedbackPane}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    {!isWidthDown('xs', width) ?
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography align={'center'}>
                                    <FormattedMessage id={'have_questions'}/>?
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <RequestCallForm/>
                            </Grid>
                        </Grid>
                        :
                        <RequestCallForm/>
                    }
                </div>
            </Modal>
        </div>
    )
};

Feedback.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    closeFeedbackPane: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    open: state.feedback.open
});
const mapDispatchToProps = dispatch => ({
    closeFeedbackPane: () => dispatch(closeFeedback())
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(Feedback)))