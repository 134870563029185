import {SET_SCROLLER, SET_SECTION} from "./landingAction";

const stateInit = {
    section: 'intro',
    scroller: '',
};

const landingReducer = (state = stateInit, action) => {
    switch (action.type) {
        case SET_SECTION:
            return {
                ...state,
                section: action.section,
            };
        case SET_SCROLLER:
            return {
                ...state,
                scroller: action.scroller
            };
        default:
            return state
    }
};

export default landingReducer