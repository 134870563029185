import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from 'react-router-dom'
import { setSection } from "../pages/landing/landingOperation";
import { connect } from "react-redux";

const styles = theme => ({
    btn: {
        fontWeight: 'bold'
    }
});

const Btn = ({className, color, onClick}) => (
    <Button
        color={"inherit"}
        component={"span"}
        size={"small"}
        className={className}
        style={{color: color ? color : '#fff'}}
        onClick={onClick}
    >
        <FormattedMessage id={'to_start'}/>
    </Button>
);

const ToStart = ({classes, color, route, className, setMonitor}) => {
    const goToStart = () => {
        setMonitor('intro')
    };

    return (
        <div className={className}>
            {route ?
                (<RouterLink to={'/'} style={{textDecoration: 'none'}}>
                    <Btn color={color} className={classes.btn}/>
                </RouterLink>)
                :
                (
                    <Btn color={color} className={classes.btn} onClick={goToStart}/>
                )
            }
        </div>
    )
};

ToStart.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    route: PropTypes.bool,
    className: PropTypes.string,
    setMonitor: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    setMonitor: section => dispatch(setSection(section))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ToStart))