import { useEffect, useState } from "react";

const useWheel = () => {
    const [position, setPosition] = useState(0);
    const interval = 50;
    let counter1 = 0;
    let counter2 = 0;
    let marker = true;

    const wheelStart = event => {
        marker = false;
        wheelAct(event);
    };

    const wheelEnd = event => {
        marker = true;
        counter1 = 0;
        counter2 = 0;
        let number = new Date().getUTCMilliseconds();
        const delta = event.deltaY || event.detail || event.wheelDelta;

        if (delta < 0) {
            number = number * -1
        }

        setPosition(number);
    };

    const wheelAct = event => {
        counter2 = counter1;

        setTimeout(function () {
            if (counter2 === counter1) {
                wheelEnd(event);
            } else {
                wheelAct(event);
            }
        }, interval);
    };

    const wheel = e => {
        counter1 += 1;

        if (marker) {
            wheelStart(e)
        }
    };

    useEffect(() => {
        window.addEventListener('wheel', wheel);
        return () => window.removeEventListener('wheel', wheel);
    });

    return position
};

export default useWheel;