import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import Map from "./Map";
import { FormattedMessage } from "react-intl";
import CloseIcon from '@material-ui/icons/Close'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Container from "@material-ui/core/Container";
import { connect } from "react-redux/es/alternate-renderers";
import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import FeedbackPane from "../../../../pane/FeedbackPane";

const styles = theme => ({
    root: {
        height: '100%',
        position: 'relative'
    },
    topGrid: {
        height: '40%',
        paddingTop: 70,
    },
    bottomGrid: {
        height: '60%',
        position: 'relative'
    },
    title: {
        fontSize: '2.57rem',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    link: {
        textDecoration: 'none',
        display: 'block',
        color: '#000'
    },
    bankBtn: {
        position: 'absolute',
        right: theme.spacing(5),
        zIndex: 100,
        boxShadow: 'none',
        backgroundColor: '#000',
        borderColor: '#000',
        color: '#fff',
        borderRadius: 0,
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#000',
            borderColor: '#000',
        },
        [theme.breakpoints.down('xs')]: {
            right: theme.spacing(),
        },
    },
    requisites: {
        position: 'absolute',
        zIndex: theme.zIndex.appBar + 10,
        width: '100%',
        height: '100%',
        background: '#000',
        color: '#fff',
    },
    container: {
        height: '100%',
    },
    close: {
        position: 'absolute',
        right: 0,
    },
    feedback: {
        position: 'absolute',
        right: 0,
        top: theme.spacing(12),
        writingMode: 'vertical-lr',
    }
});

export const TypographyLink = props => <Typography component="a" {...props} />;
const timeout = 800;

const Contact = ({classes, width, scroller}) => {
    const [close, setClose] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setClose(!close);

    useEffect(() => {
        setShow(false);

        if (scroller === 'contact') {
            setShow(true)
        }

    }, [scroller]);

    return (
        <Grid container className={classes.root}>
            <FeedbackPane className={classes.feedback} color={"#000"}/>
            <Grid item xs={12} className={classes.topGrid}>
                <Container fixed={true} className={classes.container}>
                    <Grid container alignContent={"center"} alignItems={"center"} style={{height: '100%'}} spacing={2}>
                        <Grid item sm>
                            <Collapse in={show} timeout={timeout}>
                                <Typography variant={'h3'} className={classes.title}>
                                    <FormattedMessage id={'menu.label.contact'}/>
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item sm>
                            <Fade in={show} timeout={timeout}>
                                <div>
                                    <Typography variant={"h6"}><FormattedMessage id={'adm_group_llc'}/></Typography>
                                    <TypographyLink
                                        href={"mailto:info@adm-group.ru"} className={classes.link}
                                    >
                                        info@adm-group.ru
                                    </TypographyLink>
                                    <TypographyLink href={"tel:+74991108889"} className={classes.link}>+7 499 110 88
                                        99</TypographyLink>
                                </div>
                            </Fade>
                        </Grid>
                        <Grid item>
                            <Fade in={show} timeout={timeout}>
                                <div>
                                    <Typography>
                                        Пресненская набережная, д.8, стр.1, <br/>
                                        IN-комната 7, офис 33, этаж 10 <br/>
                                        Москва, 123317
                                    </Typography>
                                </div>
                            </Fade>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} className={classes.bottomGrid}>
                <Button className={classes.bankBtn} onClick={handleClose}>
                    <Collapse in={show} timeout={timeout}>
                        <FormattedMessage id={"bank_requisites"}/>
                    </Collapse>
                </Button>
                <Slide in={!close} direction={"up"} timeout={timeout}>
                    <div className={classes.requisites}>
                        <IconButton color={"inherit"} className={classes.close} onClick={handleClose}>
                            <CloseIcon color={"inherit"}/>
                        </IconButton>
                        <Container className={classes.container} fixed={true}>
                            <Grid container alignContent={"center"} spacing={2} style={{height: '100%'}}>
                                <Grid item sm xs={12}>
                                    <Typography color={"inherit"}
                                                variant={isWidthDown('xs', width) ? 'body1' : 'h5'}>
                                        р/сч: № 40702810202120000288<br/>
                                        в банке: АО "АЛЬФА-БАНК"<br/>
                                        БИК: 044525593<br/>
                                        к/сч: № 30101810200000000593<br/>
                                        в ГУ БАНКА РОССИИ ПО ЦФО
                                    </Typography>
                                </Grid>
                                <Grid item sm xs={12}>
                                    <Typography color={"inherit"}
                                                variant={isWidthDown('xs', width) ? 'body1' : 'h5'}>
                                        ИНН: № 7703400911<br/>
                                        КПП: 770301001<br/>
                                        ОГРН: 5157746012287
                                    </Typography>
                                </Grid>
                                <Grid item sm={"auto"} xs={12}>
                                    <Typography color={"inherit"}
                                                variant={isWidthDown('xs', width) ? 'body1' : 'h5'}>
                                        Account 40702840502120000042<br/>
                                        Bank ALFA-BANK<br/>
                                        Swift ALFARUMMXXX<br/>
                                        27, Kalanchevskaya ul.,<br/>
                                        Moscow, Russian Federation
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Slide>
                <Map/>
            </Grid>
        </Grid>

    )
};

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    scroller: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    scroller: state.landing.scroller,
});

export default connect(mapStateToProps)(withWidth()(withStyles(styles)(Contact)))