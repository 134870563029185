import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'
import localeReducer from "../common/locale/localeReducer";
import snackbarReducer from "../common/snackbar/snackbarReducer";
import articleReducer from "../pages/article/articleRedicer";
import workReducer from "../pages/landing/section/work/workReducer";
import aboutReducer from "../pages/landing/section/about/aboutReducer";
import feedbackReducer from "../feedback/feedbackReducer";
import landingReducer from "../pages/landing/landingReducer";
import reviewReducer from "../pages/landing/section/review/reviewReducer";

const rootReducer = combineReducers({
    form: formReducer,
    locale: localeReducer,
    snackbar: snackbarReducer,
    article: articleReducer,
    about: aboutReducer,
    work: workReducer,
    feedback: feedbackReducer,
    landing: landingReducer,
    review: reviewReducer
});

export default rootReducer