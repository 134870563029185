import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import {Grid, Link, Typography} from "@material-ui/core";
import rtregions from "./images/rtregions.svg"
import alvaro from "./images/alvaro.svg"
import volga from "./images/volga.svg"
import sunerzha from "./images/sunerzha.svg"
import swes from "./images/swes.svg"
import brax from "./images/brax.svg"
import magellan from "./images/magellan.svg"
import interiotex from "./images/interiotex.svg"
import sanjet from "./images/sanjet.svg"
import ozna from "./images/ozna.svg"
import rusbandy from "./images/rusbandy.png"
import airpromvent from "./images/airpromvent.png"
import mediarik from "./images/mediarik.png"
import specmash from "./images/specmash.png"
import {TypographyLink} from "../contact/Contact";
import {FormattedMessage} from "react-intl";
import {openFeedback} from "../../../../feedback/feebackAction";
import {connect} from "react-redux";
import FacebookIcon from './icons/facebook.svg'
import TwitterIcon from './icons/twitter.svg'
import YoutubeIcon from './icons/youtube.svg'
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
    root: {
        height: '100%',
        background: '#000',
        overflow: 'hidden',
        position: 'relative'
    },
    image: {
        height: 100,
        [theme.breakpoints.down('xs')]: {
            height: 40,
        }
    },
    containerTop: {
        position: 'absolute',
        top: 70,
        bottom: 80,
        left: 0,
        right: 0,
        margin: '0 auto',
        padding: theme.spacing(2),
        width: '100%',
        maxWidth: 1440,
        overflowY: 'auto',
    },
    containerTopInner: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerBottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        borderTop: '0.3px solid rgba(255, 255, 255, .5)',
        padding: theme.spacing(2),
        margin: '0 auto',
        maxWidth: 1440,
        color: '#fff',
    },
    link: {
        textDecoration: 'none',
        color: '#fff',
        cursor: 'pointer'
    },
    icon: {
        paddingRight: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(2),
        }
    },
    copyright: {
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        }
    },
    toStart: {
        marginBottom: 50
    }
});

const partners = [
    {image: rtregions, url: 'http://rtregions.ru'},
    {image: alvaro, url: 'http://alvarobanos.ru'},
    {image: volga, url: 'https://airline.volga-dnepr.com'},
    {image: sunerzha, url: 'http://www.sunerzha.com'},
    {image: swes, url: 'https://swes.ru'},
    {image: brax, url: 'http://brox.pro'},
    {image: magellan, url: 'https://tk-magellan.ru'},
    {image: interiotex, url: 'http://www.interiotex.ru'},
    {image: sanjet, url: 'http://www.sanjet.ru'},
    {image: ozna, url: 'http://ozna.ru'},
    {image: rusbandy, url: 'http://www.rusbandy.ru'},
    {image: specmash, url: 'http://kspecmash.ru'},
    {image: airpromvent, url: 'https://www.airpromvent.ru'},
    {image: mediarik, url: ' https://mediarik.ru'},
];

const timeout = 800;

const Partner = ({classes, openFeedbackPane, width, scroller}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(false);

        if (scroller === 'partner') {
            setShow(true);
        }
    }, [scroller]);

    return (
        <div className={classes.root}>
            <Slide in={show} direction={"up"} timeout={timeout}>
                <div className={classes.containerTop}>
                    <Grid
                        container
                        className={classes.containerTopInner}
                    >
                        {partners.map((item, index) => {
                            const style = {
                                background: `url(${item.image}) center / contain no-repeat`
                            };

                            return (
                                <Grid
                                    item
                                    sm={3}
                                    xs={12}
                                    key={index}
                                    style={{padding: 5}}
                                >
                                    <Link href={item.url} target={'blank'}>
                                        <div className={classes.image} style={style}/>
                                    </Link>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </Slide>
            <Fade in={show} timeout={timeout}>
                <Grid container className={classes.containerBottom} alignContent={"center"} justify={"center"}>
                    <Grid item xs>
                        <img className={classes.icon} src={FacebookIcon} alt="facebook"/>
                        <img className={classes.icon} src={TwitterIcon} alt="twitter"/>
                        <img className={classes.icon} src={YoutubeIcon} alt="youtube"/>
                    </Grid>
                    {!isWidthDown('xs', width) &&
                    <Grid item xs>
                        <TypographyLink
                            href={"mailto:info@adm-group.ru"}
                            className={classes.link}
                            display={'block'}
                        >
                            info@adm-group.ru
                        </TypographyLink>
                        <TypographyLink
                            href={"tel:+74991108889"}
                            className={classes.link}
                        >
                            +7 499 110 88 99
                        </TypographyLink>
                    </Grid>
                    }
                    {!isWidthDown('xs', width) &&
                    <Grid item xs>
                        <Typography color={"inherit"} className={classes.link} onClick={openFeedbackPane}>
                            <FormattedMessage id={'request_call'}/>
                        </Typography>
                    </Grid>
                    }
                    <Grid item>
                        <Typography color={"inherit"} className={classes.copyright}><FormattedMessage
                            id={'adm_group_llc'}/> © 2015 - 2019</Typography>
                        <Typography color={"inherit"} className={classes.copyright}><FormattedMessage
                            id={'all_rights_reserved'}/></Typography>
                    </Grid>
                </Grid>
            </Fade>
        </div>
    )
};

Partner.propTypes = {
    classes: PropTypes.object.isRequired,
    openFeedbackPane: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
    scroller: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    scroller: state.landing.scroller
});

const mapDispatchToProps = dispatch => ({
    openFeedbackPane: () => dispatch(openFeedback())
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(Partner)))