import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import ReviewDesktop from "./ReviewDesktop";
import {getReviewList} from "./reviewOperation";
import {connect} from "react-redux";
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';
import ReviewMobile from "./ReviewMobile";
import Empty from "../../../empty/Empty";

const styles = theme => ({
    root: {
        height: '100%'
    }
});

const Review = ({classes, reviews, fetchReviews, lang, width}) => {
    const [position, setPosition] = useState(0);
    const data = reviews.slice(position, position + 2);

    useEffect(() => {
        fetchReviews();
    }, [fetchReviews, lang]);

    const handleSwipe = nav => {
        if (reviews) {
            const count = reviews.length - 2;

            if (nav === 'next') {
                if (position < count) {
                    setPosition(position + 1)
                } else {
                    setPosition(0)
                }
            } else {
                if (position > 0) {
                    setPosition(position - 1)
                } else {
                    setPosition(0)
                }
            }
        }
    };

    return (
        <div className={classes.root}>
            {data.length > 0 ?
                <div style={{height: '100%'}}>
                    {!isWidthDown('xs', width) ?
                        <ReviewDesktop reviews={data} handleSwipe={handleSwipe}/>
                        : <ReviewMobile reviews={data} handleSwipe={handleSwipe}/>
                    }
                </div> :
                <Empty/>
            }
        </div>
    )
};

Review.propTypes = {
    classes: PropTypes.object.isRequired,
    reviews: PropTypes.array.isRequired,
    fetchReviews: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    reviews: state.review.list,
    lang: state.locale.lang
});
const mapDispatchToProps = dispatch => ({
    fetchReviews: () => dispatch(getReviewList())
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(Review)))