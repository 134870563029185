import React from 'react'
import PropTypes from 'prop-types';
import { Button, createStyles, withStyles } from '@material-ui/core'
import classnames from 'classnames'
import { injectIntl } from "react-intl";

const styles = theme => createStyles({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(1)
    },
    line: {
        color: '#fff',
        width: '20%',
        borderBottom: '1px solid'
    },
});

const WorkNav = ({classes, next, prev, className, works, left, right, intl}) => {
    return (
        <div className={classnames(classes.root, className)}>
            <Button color={"inherit"} onClick={prev} size={"small"}
                    disabled={!works[works.indexOf(left) - 1]}>{intl.formatMessage({ id: 'prev' })}</Button>
            <div className={classes.line} />
            <Button color={"inherit"} onClick={next} size={"small"}
                    disabled={!works[works.indexOf(right) + 1]}>{intl.formatMessage({ id: 'next' })}</Button>
        </div>
    )
};

WorkNav.propTypes = {
    classes: PropTypes.object.isRequired,
    next: PropTypes.func.isRequired,
    prev: PropTypes.func.isRequired,
    className: PropTypes.string,
    works: PropTypes.array.isRequired,
    left: PropTypes.object.isRequired,
    right: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(WorkNav))