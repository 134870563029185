import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import LangPane from "./LangPane";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";
import { openFeedback } from "../feedback/feebackAction";
import { connect } from "react-redux";
import { setSection } from "../pages/landing/landingOperation";
import Slide from "@material-ui/core/Slide";
import FeedbackPane from "./FeedbackPane";

const styles = theme => ({
    item: {
        cursor: 'pointer'
    },
    pane: {
        height: '100%',
        position: 'absolute',
        zIndex: theme.zIndex.appBar + 1,
        writingMode: 'vertical-lr',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        right: 0,
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
        justifyContent: 'space-between'
    },
    button: {
        fontWeight: 'bold',
        fontSize: '0.75rem'
    },
    innerPane: {
        display: 'flex',
        height: '100%'
    }
});

const RightPane = ({classes, width, setMonitor, section}) => {
    return (
        <Slide direction={"left"} in={true} timeout={500}>
            <div className={classes.pane}>
                {isWidthDown('xs', width) && <LangPane/>}
                {!isWidthDown('xs', width) &&
                <Button
                    color={"inherit"}
                    component={"span"}
                    className={classes.button}
                    onClick={() => setMonitor('contact')}
                >
                    <FormattedMessage id={'contacts'}/>
                </Button>
                }
                <FeedbackPane />
            </div>
        </Slide>
    );
};

RightPane.propTypes = {
    classes: PropTypes.object.isRequired,
    openFeedbackPane: PropTypes.func.isRequired,
    setMonitor: PropTypes.func.isRequired,
};


const mapDispatchToProps = dispatch => ({
    openFeedbackPane: () => dispatch(openFeedback()),
    setMonitor: section => dispatch(setSection(section))
});
const mapStateToProps = state => ({
    lang: state.locale.lang,
    section: state.landing.section
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(RightPane)))