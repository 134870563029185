import { SET_ABOUT } from "./aboutAction";

const initState = {
    about: {}
};

const aboutReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ABOUT:
            return {
                about: action.about
            };
        default:
            return state
    }
};

export default aboutReducer;