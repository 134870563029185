import React from 'react';
import { withStyles, withWidth } from '@material-ui/core'
import PropTypes from "prop-types";
import LangPane from "./LangPane";
import { isWidthDown } from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";

const styles = theme => ({
    pane: {
        height: '100%',
        position: 'absolute',
        zIndex: theme.zIndex.appBar + 1,
        writingMode: 'vertical-lr',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        left: 0,
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
    },
});

const LeftPane = ({classes, width, section}) => {
    return (
        <Slide direction={"right"} in={true} timeout={500}>
            <div className={classes.pane}>
                {(!isWidthDown('xs', width) && section === 'intro') && <LangPane/>}
            </div>
        </Slide>
    );
};

LeftPane.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    section: state.landing.section
});

export default connect(mapStateToProps)(withWidth()(withStyles(styles)(LeftPane)))