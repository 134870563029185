import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import messages from '../common/locale/messages'
import Snackbar from "../common/snackbar/Snackbar";
import { BrowserRouter as Router, Route } from "react-router-dom";
import routes from "./routes";
import Feedback from "../feedback/Feedback";
import TagManager from 'react-gtm-module'

const styles = theme => ({
    '@global': {
        body: {
            overflow: 'hidden'
        },
        // html: {
        //     fontSize: '100%',
        // }
    },
    root: {}
});

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs);

const App = ({ classes, lang }) => {
    return (
        <IntlProvider locale={lang} defaultLocale={'en'} messages={messages[lang]}>
            <div className={classes.root}>
                <CssBaseline />
                <Router>
                    {routes.map((route, index) =>
                        <Route path={route.path} component={route.component} exact={route.exact} key={index} />
                    )}
                </Router>
                <Snackbar />
                <Feedback />
            </div>
        </IntlProvider>
    );
};

App.propTypes = {
    classes: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ lang: state.locale.lang });

export default connect(mapStateToProps, null)(withStyles(styles)(App));
