import React from 'react'
import PropTypes from 'prop-types';
import {withStyles, createStyles} from '@material-ui/core'

const styles = theme => createStyles({
    root: {
        height: '100vh',
    }
});

const Section = ({id, classes, children }) => {
    return (
        <div id={id} className={classes.root}>
            {children}
        </div>
    )
};

Section.propTypes = {
    id: PropTypes.string.isRequired,
};

export default withStyles(styles)(Section)