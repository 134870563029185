import React, { useState } from 'react'
import PropTypes from "prop-types";
import { createStyles, withStyles, withWidth } from "@material-ui/core";
import { connect } from 'react-redux'
import classnames from 'classnames';
import Typography from "@material-ui/core/Typography";
import { FormattedMessage, injectIntl } from "react-intl";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const styles = theme => createStyles({
    root: {
        height: '100%',
        color: '#fff',
        background: '#000',
        // overflow: 'hidden',
    },
    grid: {
        height: '80%',
        display: 'flex',
        overflow: 'hidden'
    },
    container: {
        height: '20%'
    },
    item: {
        transition: 'width .5s',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
    },
    itemActive: {
        cursor: 'default',
    },
    link: {
        textDecoration: 'none',
        position: 'absolute',
        color: '#fff',
        bottom: theme.spacing(4),
        left: theme.spacing(4),
        right: theme.spacing(4),
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: ["Montserrat", "Roboto"].join(","),
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem'
        }
    }
});

const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const ServiceD = ({classes, items, scroller, width, intl}) => {
    const aW = 60;
    const sW = (100 - aW) / (items.length - 1);
    const [slided, setSlided] = useState(true);
    const [active, setActive] = useState(items[0]);
    const timer = 500;

    const handleActive = async (e, item) => {
        if (slided && item !== active) {
            setActive(item);
            setSlided(false);
            const target = e.currentTarget;
            target.style.width = aW + '%';

            if (target.previousElementSibling) {
                resize(target, 'left', item);
            }

            if (target.nextElementSibling) {
                resize(target, 'right', item);
            }

            await sleep(timer);
            setSlided(true);
        }
    };

    const resize = (target, action) => {
        if (target) {

            if (action === 'left' && target.previousElementSibling) {
                target.previousElementSibling.style.width = sW + '%';
                resize(target.previousElementSibling, action);
            }

            if (action === 'right' && target.nextElementSibling) {
                target.nextElementSibling.style.width = sW + '%';
                resize(target.nextElementSibling, action);
            }
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.grid}>
                {items.map((item, index) => {
                    let style = {
                        background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${item.image}) center / cover no-repeat`,
                        width: sW + '%'
                    };

                    if (item === active) {
                        style = {
                            background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${item.image}) center / cover no-repeat`,
                            width: aW + '%'
                        }
                    }

                    const title = intl.formatMessage({id: item.title});
                    return (
                        <div
                            key={index}
                            className={classnames(classes.item, item === active && classes.itemActive)}
                            style={style}
                            onClick={(e) => handleActive(e, item)}
                        >
                            <Slide in={scroller === 'service'} direction={"up"} timeout={timer}>
                                {item === active ?
                                    <Link to={'/articles/' + item.slug} className={classes.link}>
                                        <Fade in={slided && scroller === 'service'} timeout={timer}>
                                            <Typography
                                                variant={"h2"}
                                                className={classes.title}
                                                component={"span"}
                                            >
                                                {title}
                                            </Typography>
                                        </Fade>
                                    </Link>:
                                    <div className={classes.link}>
                                        <Typography
                                            variant={"h2"}
                                            className={classes.title}
                                            display={"block"}
                                            align={"center"}
                                        >
                                            {title[0]}
                                        </Typography>
                                    </div>

                                }

                            </Slide>
                        </div>
                    )
                })}
            </div>
            <Container className={classes.container}>
                <Grid container style={{height: '100%'}} alignContent={"center"}>
                    {active.content.map((val, key) => {
                        return (
                            <Grid
                                key={key}
                                item
                                sm
                                xs={12}
                            >
                                <Fade in={slided && scroller === 'service'} timeout={timer}>
                                    <div>
                                        <div className={classes.icon}>{val.icon}</div>
                                        <div>
                                            <Typography color={"inherit"}>
                                                <FormattedMessage id={val.text} defaultMessage={val.text}/>
                                            </Typography>
                                        </div>
                                    </div>
                                </Fade>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </div>
    )
};

ServiceD.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    scroller: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    scroller: state.landing.scroller,
});

export default connect(mapStateToProps)(injectIntl(withWidth()(withStyles(styles)(ServiceD))))