export default {
    en: {
        'development.content.text1': 'Developing MVP starter version of product',
        'development.content.text2': 'Developing CRM and CMS modules',
        'development.content.text3': 'Startups',
        'development.content.text4': 'Developing with blockchain technologies',

        'administration.content.text1': 'Servers',
        'administration.content.text2': 'Sites',
        'administration.content.text3': 'Networks',
        'administration.content.text4': 'Sell, mount and setup digital and telecommunication devices',

        'seo.content.text1': 'Internal and external optimization',
        'seo.content.text2': 'Working with metatags and headers',
        'seo.content.text3': 'Re-linking',
        'seo.content.text4': 'Analysis',

        'support.content.text1': 'Consulting services',
        'support.content.text2': 'Legal services',
        'support.content.text3': 'Help with choice devices ana stack of technologies',
        'support.content.text4': 'Support in real time',
    },
    ru: {
        'development.content.text1': 'Разработка МVP - начальной версии проекта',
        'development.content.text2': 'Разработка модулей под CRM, CMS, написание индивидуальных',
        'development.content.text3': 'Реализация стартапов любой сложности',
        'development.content.text4': 'Разработка с использованием blockchain технологий',

        'administration.content.text1': 'Сервера',
        'administration.content.text2': 'Сайты',
        'administration.content.text3': 'Сети',
        'administration.content.text4': 'Продаем, монтируем и настраиваем цифровые и телекоммуникационные устройства',

        'seo.content.text1': 'Внутренняя и внешняя оптимизация',
        'seo.content.text2': 'Работа с метатегами и заголовкам',
        'seo.content.text3': 'Перелинковка',
        'seo.content.text4': 'Анализ',

        'support.content.text1': 'Консалтинговые услуги',
        'support.content.text2': 'Юридические услуги',
        'support.content.text3': 'Помощь в выборе оборудования и стека технологий',
        'support.content.text4': 'Поддержка в реальном времени',
    }
}