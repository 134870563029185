import axios from "../utils/axios";

export const requestFeedback = async values => {
    return await axios({
        method: 'post',
        url: '/order/call',
        data: {
            orderCall: values
        },
    });
};