import { SET_ARTICLE } from "./articleAction";
import lodash from 'lodash'


const initState = {
    current: {},
    list: [],
    currentLoaded: false,
};

const articleReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ARTICLE:
            return {
                ...state,
                current: action.article,
                currentLoaded: !lodash.isEmpty(action.article)
            };
        default:
            return state
    }
};

export default articleReducer;