import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import renderTextField from "../utils/form/renderTextField";
import { Field, reduxForm } from 'redux-form'
import grey from '@material-ui/core/colors/grey'
import {injectIntl, intlShape} from 'react-intl';
import { sendFeedbackForm } from "./feedbackOperation";
import { connect } from "react-redux";

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    submitWrapper: {
        textAlign: 'right'
    },
    submit: {
        color: '#fff',
        backgroundColor:  grey[900],
        '&:hover': {
            backgroundColor: grey[800],
        },
        display: 'inline-block'
    },
    textField: {
        margin: `${theme.spacing(2)}px 0`
    }
});

const FeedbackForm = ({classes, handleSubmit, intl, requestFeedback}) => {
    const onSubmit = values => requestFeedback(values);


    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <Field
                id={'name'}
                name={'name'}
                label={intl.formatMessage({id: 'label.name'})}
                component={renderTextField}
                className={classes.textField}
                placeholder={''}
            />
            <Field
                id={'phone'}
                name={'phone'}
                label={intl.formatMessage({id: 'label.phone'})}
                component={renderTextField}
                className={classes.textField}
                placeholder={''}
            />
            <Field
                id={'description'}
                name={'description'}
                label={intl.formatMessage({id: 'label.description'})}
                component={renderTextField}
                multiline={true}
                rowsMax={10}
                rows={10}
                className={classes.textField}
                placeholder={''}
            />
            <div className={classes.submitWrapper}>
                <Button variant={"contained"} className={classes.submit} type={"submit"}>
                    {intl.formatMessage({id: 'form.send_form'})}
                </Button>
            </div>
        </form>
    )
};

FeedbackForm.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    requestFeedback: PropTypes.func.isRequired,
};

const FeedbackFormRedux = reduxForm({
    form: 'feedbackForm'
})(FeedbackForm);


const mapStateToProps = state => ({
    lang: state.locale.lang
});
const mapDispatchToProps = dispatch => ({
    requestFeedback: values => dispatch(sendFeedbackForm(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(FeedbackFormRedux)))