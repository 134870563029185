import { SubmissionError, reset } from "redux-form";
import { requestFeedback } from "../api/feedback";
import { openSnackbar } from "../common/snackbar/snackbarAction";

export const sendFeedbackForm = values => {
    return async dispatch => {
        try {
            const response = await requestFeedback(values);
            const message = response.data.data;
            dispatch(openSnackbar(message, 'success'));
            dispatch(reset('feedbackForm'));
        } catch (e) {
            const errors = e.response.data.errors;
            throw new SubmissionError(errors);
        }
    }
};