import video1 from './video/1.mp4'
import video2 from './video/2.mp4'
import video3 from './video/3.mp4'
import video4 from './video/4.mp4'
import technology from './images/technology.jpg'

export default [
    {
        title: 'intro.slider.title1',
        description: 'intro.slider.description1',
        path: video1,
        type: 'video'
    },
    {
        title: 'intro.slider.title2',
        description: 'intro.slider.description2',
        path: video2,
        type: 'video'
    },
    {
        title: 'intro.slider.title3',
        description: 'intro.slider.description3',
        path: video3,
        type: 'video'
    },
    {
        title: 'intro.slider.title4',
        description: 'intro.slider.description4',
        path: video4,
        type: 'video'
    },
    {
        title: 'intro.slider.title5',
        description: 'intro.slider.description5',
        path: technology,
        type: 'image'
    }
];