import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLocale } from "../common/locale/localeOperation";
import { Button, Typography } from "@material-ui/core";

const styles = theme => ({
    lang: {
        display: 'flex',
    },
    button: {
        fontWeight: 'bold',
        fontSize: '0.75rem',
        opacity: .5
    }
});

const LangPane = ({ classes, setLang, lang }) => {
    return (
        <div className={classes.lang}>
            <Button
                color={"inherit"}
                component={Typography}
                className={classes.button}
                style={lang === 'en' ? {opacity: 1} : {}}
                onClick={() => setLang('en')}
            >
                English
            </Button>
            <Button
                color={"inherit"}
                component={Typography}
                className={classes.button}
                style={lang === 'ru' ? {opacity: 1} : {}}
                onClick={() => setLang('ru')}
            >
                Русский
            </Button>
        </div>
    )
};

LangPane.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    setLang: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    lang: state.locale.lang
});

const mapDispatchToProps = dispatch => ({
    setLang: lang => dispatch(setLocale(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LangPane))