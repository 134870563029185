import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles'
import ReactMapGL, { Marker } from 'react-map-gl'
import marker from './marker.svg'

const styles = theme => ({
    root: {
        height: '100%'
    },
    marker: {
        width: 26,
        height: 36
    }
});

const viewport = {
    width: '100%',
    height: '100%',
    latitude: 55.747115,
    longitude: 37.539078,
    zoom: 15,
};

const Map = ({ classes }) => {
    return (
        <div className={classes.root}>
            <ReactMapGL
                {...viewport}
            >
                <Marker latitude={viewport.latitude} longitude={viewport.longitude} offsetLeft={-20} offsetTop={-10}>
                    <img src={marker} alt="marker" className={classes.marker} />
                </Marker>
            </ReactMapGL>
        </div>
    )
};

Map.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Map)