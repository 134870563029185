import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core'
import classnames from 'classnames';
import {Grid, Typography} from "@material-ui/core";
import {injectIntl} from "react-intl";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import WorkNav from "./WorkNav";
import sleep from '../../../../utils/sleep'
import {CoverLink} from "./Work";

const styles = theme => ({
    root: {
        height: '100%',
        overflow: 'hidden'
    },
    grid: {
        position: 'relative'
    },
    cover: {
        height: '60%',
        position: 'relative'
    },
    coverCenter: {
        bottom: 0,
        position: "absolute",
        width: '100%',
        left: -50
    },
    coverRightPane: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        right: 0
    },
    coverLeftPane: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
    },
    nav: {
        position: 'absolute',
        bottom: 0,
    },
    line: {
        color: '#fff',
        width: '20%',
        borderBottom: '1px solid'
    },
    rightSign: {
        position: 'absolute',
        color: '#fff',
        textDecoration: 'none',
        right: -theme.spacing(4),
        padding: theme.spacing(1),
        background: 'rgba(234, 44, 44, .9)',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        textTransform: 'uppercase',
        fontWeight: 'bold',
        zIndex: 100,
        top: '50%',
        transform: 'translateY(-50%)'
    },
    leftSign: {
        position: 'absolute',
        color: '#fff',
        textDecoration: 'none',
        left: -theme.spacing(4),
        padding: theme.spacing(1),
        background: 'rgba(234, 44, 44, .9)',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        textTransform: 'uppercase',
        fontWeight: 'bold',
        zIndex: 100,
        top: '50%',
        transform: 'translateY(-50%)'
    },
    more: {
        color: '#fff',
        textDecoration: 'none',
        padding: theme.spacing(1),
        background: 'rgba(234, 44, 44, .9)',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    subheader: {
        textTransform: 'uppercase',
        fontFamily: ['Montserrat', 'Roboto'].join(','),
        fontWeight: 'bold',
    }
});

const WorkDesktop = ({classes, works, intl, scroller}) => {
    const [center, setCenter] = useState({});
    const [left, setLeft] = useState({});
    const [right, setRight] = useState({});
    const [open, setOpen] = useState(false);
    const timeout = 800;

    useEffect(() => {
        if (works.length > 0) {
            setLeft(works[0]);
            setCenter(works[1]);
            setRight(works[2]);
        }
    }, [works]);

    useEffect(() => {
        setOpen(false);

        if (scroller === 'work') {
            setOpen(true);
        }
    }, [scroller]);

    const next = async () => {
        setOpen(false);
        await sleep(timeout);
        const index = works.indexOf(right);

        if (works[index + 1]) {
            setLeft(center);
            setCenter(right);
            setRight(works[index + 1])
        }

        setOpen(true);
    };

    const prev = async () => {
        setOpen(false);
        await sleep(timeout);
        const index = works.indexOf(left);

        if (works[index - 1]) {
            setRight(center);
            setCenter(left);
            setLeft(works[index - 1])
        }

        setOpen(true);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={2}>
                <Slide direction={"up"} in={open} timeout={timeout}>
                    <div
                        className={classes.cover}
                        style={{
                            background: `url(${left.image}) center / cover no-repeat`
                        }}
                    >
                        <Typography
                            component={CoverLink}
                            className={classes.rightSign}
                            variant={'h1'}
                            to={'/articles/' + left.slug}
                        >
                            {left.title && left.title[0]}
                        </Typography>
                    </div>
                </Slide>
            </Grid>
            <Grid item xs={8}>
                <Grid container style={{height: '100%'}}>
                    <Grid item xs={6} className={classes.grid}>
                        <Slide direction={"down"} in={open} timeout={timeout}>
                            <div
                                className={classnames(classes.cover, classes.coverCenter)}
                                style={{
                                    background: `url(${center.image}) center / cover no-repeat`,
                                }}
                            >
                                <Typography
                                    component={CoverLink}
                                    className={classes.rightSign}
                                    variant={'h1'}
                                    to={'/articles/' + center.slug}
                                >
                                    {center.title && center.title[0]}
                                </Typography>
                            </div>
                        </Slide>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <div className={classes.content}>
                            <Fade in={open} timeout={timeout}>
                                <Typography
                                    variant={"h6"}
                                    className={classes.subheader}
                                    gutterBottom
                                >
                                    {center.title}
                                </Typography>
                            </Fade>
                            <Fade in={open} timeout={timeout}>
                                <Typography variant={"body1"}
                                            dangerouslySetInnerHTML={{__html: center.shortDescription}}/>
                            </Fade>
                            <Fade in={open} timeout={timeout}>
                                <Typography
                                    component={CoverLink}
                                    variant={'body2'}
                                    className={classes.more}
                                    align={"right"}
                                    to={'/articles/' + center.slug}
                                >
                                    {intl.formatMessage({id: 'link.more'})}
                                </Typography>
                            </Fade>

                        </div>
                        <WorkNav
                            works={works}
                            left={left}
                            right={right}
                            prev={prev}
                            next={next}
                            className={classes.nav}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Slide direction={"up"} in={open} timeout={timeout}>
                    <div
                        className={classes.cover}
                        style={{
                            background: `url(${right.image}) center / cover no-repeat`
                        }}
                    >
                        <Typography
                            component={CoverLink}
                            className={classes.leftSign}
                            variant={'h1'}
                            to={'/articles/' + left.slug}
                        >
                            {right.title && right.title[0]}
                        </Typography>
                    </div>
                </Slide>
            </Grid>
        </Grid>
    )
};

WorkDesktop.propTypes = {
    classes: PropTypes.object.isRequired,
    works: PropTypes.array,
    scroller: PropTypes.string.isRequired,
};

export default injectIntl((withStyles(styles)(WorkDesktop)))