import { SET_REVIEWS } from "./reviewAction";

const stateInit = {
    list: []
};

const reviewReducer = (state = stateInit, action) => {
    switch(action.type) {
        case SET_REVIEWS:
            return {
                list: action.reviews
            }
        default:
            return state;
    }
};

export default reviewReducer