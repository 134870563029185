import React, { useEffect, useRef } from 'react';
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getArticle } from "./articleOperation";
import { Grid, Typography } from "@material-ui/core";
import LangPane from "../../pane/LangPane";
import ToStart from "../../pane/ToStart";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";
import withWidth from "@material-ui/core/withWidth";
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
    root: {
        height: '100vh',
        position: 'relative',
        zIndex: 100
    },
    leftSide: {
        position: 'relative',
        minHeight: '40%',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            height: '40%',
        }
    },
    rightSide: {
        position: 'relative',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            height: '60%',
        },
        overflow: 'hidden',
        overflowY: 'auto'
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    text: {
        fontSize: 108,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: "'Montserrat', 'Roboto'"
    },
    svg: {
        width: '100%',
        fontSize: 108,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    lang: {
        position: 'absolute',
        right: 0,
        color: '#fff',
        background: 'rgba(0,0,0,.5)'
    },
    toStart: {
        position: 'absolute',
        left: 0,
        bottom: theme.spacing(2),
        writingMode: 'vertical-lr',
        zIndex: theme.zIndex.appBar + 1
    },
    mark: {
        background: 'rgba(0, 0, 0, 1)',
        color: '#fff',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mixBlendMode: 'multiply',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        position: 'absolute',
        transition: '1s width',
        zIndex: theme.zIndex.appBar
    }
});

const Article = ({classes, match, fetchArticle, article, lang, loaded, width}) => {
    const mark = useRef();
    const cover = useRef();
    const resizeMark = () => {
        if (mark.current) {
            mark.current.style.width = '30%';
        }
    };

    useEffect(() => {
        fetchArticle(match.params.slug);
    }, [fetchArticle, match.params.slug, lang]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loaded) {
                resizeMark();
                clearInterval(timer)
            }

        }, 500)
    }, [loaded]);

    return (
        <div className={classes.root}>
            {Object.keys(article).length !== 0 ?
                <Grid container style={{height: '100%'}}>
                    <Helmet
                        titleTemplate="ADM - %s"
                    >
                        <title>
                            {article.title}
                        </title>
                    </Helmet>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                        style={{background: `url(${article.image}) center / cover no-repeat`}}
                        className={classes.leftSide}
                        ref={cover}
                    >
                        <div className={classes.lang}>
                            <LangPane/>
                        </div>
                        <Typography
                            className={classes.mark}
                            variant={"h1"}
                            component={"span"}
                            ref={mark}
                            onLoad={() => resizeMark()}
                        >
                            {article.title[0]}
                        </Typography>
                        <ToStart route={true} className={classes.toStart}/>
                    </Grid>
                    <Grid item sm={7} xs={12} className={classes.rightSide}>
                        <Container fixed className={classes.container}>
                            <Fade in={true}>
                                <div>
                                    <Typography variant={'h3'} align={'center'}>{article.title}</Typography>
                                    <Typography dangerouslySetInnerHTML={{__html: article.description}}/>
                                </div>
                            </Fade>
                        </Container>
                    </Grid>
                </Grid> :
                <div></div>
            }
        </div>
    )
};

Article.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    fetchArticle: PropTypes.func.isRequired,
    article: PropTypes.object.isRequired,
    loaded: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    article: state.article.current,
    loaded: state.article.currentLoaded,
    lang: state.locale.lang
});
const mapDispatchToProps = dispatch => ({
    fetchArticle: slug => dispatch(getArticle(slug))
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(Article)))